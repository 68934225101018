import { type Season } from 'types/sportsdata';

export const CBB_SEASONS: Season[] = [
  {
    seasonId: '2024POST',
    seasonYear: '2024',
    seasonEndDate: '2024-04-10T00:00:00Z',
    label: '2023-2024 Post Season',
    league: 'cbb',
  },
];

export const CURRENT_CBB_FUTURES_SEASON: Season = {
  seasonId: '2024',
  seasonYear: '2024',
  seasonEndDate: '2024-04-10T00:00:00Z',
  label: "NCAA Men's Basketball 2023-2024 Season",
  league: 'cbb',
};
