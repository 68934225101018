import { useState } from 'react';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { postTournament } from 'api/tournaments';
import { DateInput } from 'components/AddPredictionForm/components/DateInput';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { UserSearch } from 'components/UserSearch';
import { type PostTournament } from 'types/tournaments';
import { type UserSummary } from 'types/user';

export const AddTournament = () => {
  const navigate = useNavigate();

  // Page Status
  const [placingTournament, setPlacingTournament] = useState(false);

  // Tournament Content
  const [tournamentTitle, setTournamentTitle] = useState<string>('');
  const [tournamentGoLiveTime, setTournamentGoLiveTime] = useState<Date>(
    new Date(Date.now())
  );
  const [tournamentCloseTime, setTournamentCloseTime] = useState<Date>(
    new Date(Date.now())
  );
  const [numberOfRounds, setNumberOfRounds] = useState<string>('1');
  const [chosenAuthor, setChosenAuthor] = useState<UserSummary>();

  const submitTournamentEnabled = () => {
    return (
      tournamentGoLiveTime &&
      tournamentCloseTime &&
      tournamentTitle &&
      chosenAuthor &&
      Number(numberOfRounds)
    );
  };

  const submitTournament = async () => {
    if (!chosenAuthor) {
      console.error('Error adding tournament: no author chosen for tournament');
      return;
    }
    const postBody: PostTournament = {
      tournamentTitle: tournamentTitle,
      numberOfRounds: Number(numberOfRounds),
      tournamentGoLiveTime: tournamentGoLiveTime,
      tournamentCloseTime: tournamentCloseTime,
      authorUserId: chosenAuthor.userId,
    };

    // TODO[#56]: Optimistic Mutation?
    setPlacingTournament(true);
    try {
      await postTournament(postBody);
    } catch (err) {
      console.error('Error adding tournament.', err);
    }
    // TODO[#57]: Invalidate Tournament query once view tournaments its completed.
    setPlacingTournament(false);
    navigate('/viewTournaments');
  };

  const clearAllFields = async () => {
    console.error('Should clear');
  };

  return (
    <div className="add-tournament-container">
      <h2>Add Tournament</h2>
      <div>
        <p>Tournament Title</p>
        <Input
          id={'Tournament title field'}
          accessibilityLabel={'Tournament title input'}
          value={tournamentTitle}
          handleOnChange={setTournamentTitle}
        />
      </div>
      <div>
        <p>Tournament Go Live Time</p>
        <DateInput
          date={tournamentGoLiveTime}
          setDate={setTournamentGoLiveTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Tournament Close Time</p>
        <DateInput
          date={tournamentCloseTime}
          setDate={setTournamentCloseTime}
          disabled={placingTournament}
          showTime={true}
        />
      </div>
      <div>
        <p>Number of Rounds in Tournament</p>
        <Input
          id={'Tournament rounds field'}
          accessibilityLabel={'Tournament max rounds input'}
          value={numberOfRounds}
          placeholder={typeof numberOfRounds == 'string' ? numberOfRounds : ''}
          handleOnChange={setNumberOfRounds}
        />
      </div>
      <div>
        <p>Tournament Author</p>
        <UserSearch
          chosenAuthor={chosenAuthor}
          setChosenAuthor={setChosenAuthor}
        />
        {chosenAuthor && <p></p>}
      </div>
      <Button
        accessibilityLabel="Create Tournament Button"
        handleOnClick={() => {
          setPlacingTournament(true);
          submitTournament();
          clearAllFields();
        }}
        type="primary"
        text="Create Tournament"
        disabled={!submitTournamentEnabled() || placingTournament}
        loading={placingTournament}
      />
    </div>
  );
};

export default AddTournament;
